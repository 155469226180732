import App from './App'
import reportWebVitals from './reportWebVitals'

import React from 'react'
import './index.css'
import './services/i18n.service'

import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
  )
} else {
  console.error('Root element not found.')
}

reportWebVitals(() => {})
