export function nullToEmpty(data: any) {
  if (data === null) data = ''
  return data
}

export function nullToCero(data: any) {
  if (!data) return 0
  return data
}

export function nanToCero(result: any) {
  if (isNaN(result)) return 0
  return result
}

export function infinityToCero(result: any) {
  if (isFinite(result)) return result
  return 0
}

export function isEmptyOrSpaces(str: string) {
  if (str === null || str.match(/^\s*$/) !== null) return null
  return str
}

export function convertToCamelCase(name: string) {
  const words = name.split(' ')
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase()
    }
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  const camelCaseName = camelCaseWords.join('')
  return camelCaseName
}

export function toSnakeCase(text: string) {
  return text
    .replace(/\s+/g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
}

export function recogniseLink(text: string) {
  const regex = /\[([^\]]+)]\(([^)]+)\)/
  const match = text.match(regex)
  const linkText = match ? match[1] : ''
  const linkUrl = match ? match[2] : ''
  const parts = text.split(regex)
  return {
    linkText,
    linkUrl,
    parts,
  }
}
