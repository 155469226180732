import { LinkProps } from './useLink'

import React from 'react'

import classNames from 'classnames'

const LinkView = ({
  disabled,
  className,
  onClick,
  children,
  selected,
}: React.PropsWithChildren<LinkProps>) => {
  const linkClass = classNames(
    'no-underline',
    'inline-block',
    'pb-1',
    'relative',
    "before:content-['']",
    'before:absolute',
    'before:left-0',
    'before:bottom-0',
    'before:h-0.5',
    'before:w-0',
    'before:bg-blue-800',
    'before:transition-width',
    'before:duration-300',
    'before:ease-in-out',
    {
      'text-grey-800': !disabled && !selected,
      'hover:before:w-full': !disabled,
      'hover:text-blue-800': !disabled,
      'font-bold': !disabled,
      'text-grey-600': disabled,
      'hover:text-black': disabled,
      'hover:before:w-0': disabled,
      'before:w-full': selected,
      'text-blue-800': selected,
    },
  )
  return (
    <button
      className={`${linkClass} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default LinkView
