import { useEffect, useState } from 'react'

import classNames from 'classnames'
import Header from 'components/Organisms/Header'
import { useAsync, useFetchAndLoad } from 'hooks'
import { FaArrowUp } from 'react-icons/fa'
import { getSheet } from 'services/faqs.service'

export interface BannerData {
  landing: string
  active: boolean
  message: string
  url: string
  trackingCode: string
}

const HomeLayout = ({ children }: any) => {
  const [scrolled, setScrolled] = useState(false)
  const [data, setData] = useState<BannerData>({
    landing: '',
    active: false,
    message: '',
    url: '',
    trackingCode: '',
  })
  const { callEndpoint } = useFetchAndLoad()
  const getData = async () => await callEndpoint(getSheet())
  const saveData = (data: any) => {
    const rows = data?.split('\r\n').splice(1)
    const col = rows?.map((row: any) => {
      return row.split('\t')
    })
    const rowCiclogreen = col.find((item: any) => item[0] === 'fms')
    if (rowCiclogreen)
      setData({
        landing: rowCiclogreen[0],
        active: rowCiclogreen[1] === 'TRUE',
        message: rowCiclogreen[2],
        url: rowCiclogreen[3],
        trackingCode: rowCiclogreen[4],
      })
  }
  useAsync(getData, saveData, () => {}, [])
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const headerClassName = classNames(
    'bg-white',
    'fixed',
    'w-full',
    'top-0',
    'left-0',
    'mx-auto',
    'z-50',
    'transition-all',
    'duration-500',
    { 'shadow-md': scrolled },
  )
  const upButtonClassName = classNames(
    'fixed',
    'bottom-6',
    'right-6',
    'sm:bottom-16',
    'sm:right-16',
    'z-50',
    'transition-all',
    'duration-300',
    'ease-in-out',
    'transform',
    {
      'scale-0': !scrolled,
      'scale-100': scrolled,
    },
  )
  const dataActive = data?.active
  const bodyClassName = classNames('mt-36', {
    'mt-48': dataActive,
  })
  const aClassName = classNames(
    'text-white',
    'font-semibold',
    'transition-all',
    'duration-1000',
    'ease-in-out',
    'transform',
    {
      'h-0': !dataActive,
      'h-full': dataActive,
    },
  )
  const textClassName = classNames(
    'bg-blue-800',
    'w-full',
    'text-center',
    'p-3',
    'transition-all',
    'duration-1000',
    'ease-in-out',
    'transform',
    {
      'opacity-0': !dataActive,
      'opacity-100': dataActive,
    },
  )
  const handleOnClick = (sectionId: any) => {
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId)
      if (sectionElement) {
        const headerOffset = 100
        const elementPosition = sectionElement.getBoundingClientRect().top
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
  }
  return (
    <div className="w-screen h-full">
      <div className={headerClassName}>
        <div className={aClassName}>
          <a
            href={`${data?.url}?source=${data?.trackingCode}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={textClassName}>{data?.message}</div>
          </a>
        </div>
        <Header logo="logo.png" onClick={handleOnClick} />
      </div>
      <div className={bodyClassName}>{children}</div>
      <div className={upButtonClassName}>
        <button
          className="rounded-full p-3 bg-blue-800 text-2xl shadow-md transition-all duration-500"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <FaArrowUp className="scrollTop text-white" />
        </button>
      </div>
    </div>
  )
}

export default HomeLayout
