import LinkView from './LinkView'
import useLink, { LinkProps } from './useLink'

import React from 'react'

import { withHook } from 'utilities'

const Link: React.FC<LinkProps> = withHook({
  hook: useLink,
  component: LinkView,
})
export default Link
