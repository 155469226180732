import { useTranslation } from 'react-i18next'

export interface LoadingProps {
  message?: string
}
export default function useLoading({ message }: LoadingProps) {
  const { t } = useTranslation()
  return {
    message,
    t,
  }
}
