import axios from 'axios'
import { loadAbort } from 'utilities'

export const getSheet = () => {
  const controller = loadAbort()
  const url = `https://docs.google.com/spreadsheets/d/e/${process.env.REACT_APP_SPREADSHEETS_ID}/pub?output=tsv`
  return {
    call: axios.get(url, { signal: controller.signal }),
    controller,
  }
}

export const getSheetTest = (url: string) => {
  const controller = loadAbort()
  return {
    call: axios.get(url, { signal: controller.signal }),
    controller,
  }
}
