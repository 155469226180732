import { Route } from '../models'

import React from 'react'

import HomeLayout from 'layouts/HomeLayout'

const Home = React.lazy(() => import('../pages/Home'))
const Error = React.lazy(() => import('../pages/Error'))
const Blog = React.lazy(() => import('../pages/Blog/Blog'))
const Post = React.lazy(() => import('../pages/Post/Post'))

export const routes: Route[] = [
  {
    path: '/',
    page: Home,
    layout: HomeLayout,
  },
  {
    path: 'posts',
    page: Blog,
    layout: HomeLayout,
  },
  {
    path: 'posts/:id',
    page: Post,
    layout: HomeLayout,
  },
  {
    path: '*',
    page: Error,
    layout: HomeLayout,
  },
]
