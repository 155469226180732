export interface HamburguerProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  className?: string
}

const Hamburguer = ({ setIsOpen, isOpen, className }: HamburguerProps) => {
  const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-black-800 transition ease transform duration-300`
  return (
    <div className={className}>
      <button
        className="flex flex-col h-12 w-12 justify-center items-center group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={`${genericHamburgerLine} ${
            isOpen
              ? 'rotate-45 translate-y-3 opacity-100 group-hover:opacity-100'
              : 'opacity-50 group-hover:opacity-100'
          }`}
        />
        <div
          className={`${genericHamburgerLine} ${
            isOpen ? 'opacity-0' : 'opacity-50 group-hover:opacity-100'
          }`}
        />
        <div
          className={`${genericHamburgerLine} ${
            isOpen
              ? '-rotate-45 -translate-y-3 opacity-100 group-hover:opacity-100'
              : 'opacity-50 group-hover:opacity-100'
          }`}
        />
      </button>
    </div>
  )
}

export default Hamburguer
