import React, { MouseEventHandler } from 'react'

export interface LinkProps extends React.PropsWithChildren<{}> {
  className?: string
  disabled?: boolean
  selected?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default function useLink({
  disabled = false,
  className,
  children,
  onClick,
  selected,
}: LinkProps) {
  return {
    disabled,
    className,
    onClick,
    children,
    selected,
  }
}
