export const benefitsMock: string[] = [
  'co2',
  'absenteeism',
  'traffic',
  'ptt',
  'sustanaible',
]

export const actionsMock: string[] = [
  'incentives',
  'carpooling',
  'mobility',
  'remote',
  'transport',
  'parking',
  'electricCar',
  'bus',
  'communicate',
]
